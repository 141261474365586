<template lang="html">
  <div class="waterElectricity">
    <div class="boxItem">
      <div class="title">智能水表</div>
      <div class="box">
        <div class="list" v-for="item in waterList" :key="item.meter_id" @click="inquire(item.meter_number)">
          <div class="name">{{ item.merchants_stall_name }} - {{ item.meter_number }}</div>
          <img class="right" src="../assets/image/my/y.png" alt />
        </div>
      </div>
    </div>
    <div class="boxItem">
      <div class="title">智能电表</div>
      <div class="box">
        <div class="list" v-for="item in electricityList" :key="item.meter_id" @click="inquire(item.meter_number)">
          <div class="name">{{ item.merchants_stall_name }} - {{ item.meter_number }}</div>
          <img class="right" src="../assets/image/my/y.png" alt />
        </div>
      </div>
    </div>
    <van-popup v-model:show="show" round closeable>
      <div class="modelBox">
        <div class="title">查询结果</div>
        <div class="content">
          <div class="list">表号：{{ inquireData?.result.meter_id || 0 }}</div>
          <!-- <div class="list">总量：{{ inquireData?.result.total_e || 0 }}</div> -->
          <div class="list">剩余：{{ inquireData?.result.data || 0 }}</div>
          <div class="list">余额：{{ inquireData?.result2.money || 0 }}</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { inject, ref } from 'vue';
import { getinquire } from '@/service/contribute';
const $require = inject('$require');

let show = ref(false);
let electricityList = ref([]);
let waterList = ref([]);
let inquireData = ref(null);

const getElectricityList = () => {
  $require.HttpGet('/property_daily/merchant/meter/list', {
    meter_type: 2
  }).then((res) => {
    electricityList.value = res.data?.list || [];
  });
}

const getWaterList = () => {
  $require.HttpGet('/property_daily/merchant/meter/list', {
    meter_type: 1
  }).then((res) => {
    waterList.value = res.data?.list || [];
  });
}

const inquire = (meterNumber) => {
  getinquire({ meter_number: meterNumber }).then((res) => {
    inquireData.value = res.data;
    show.value = true;
  });
}

getWaterList();
getElectricityList();
</script>

<style lang="scss" scoped>
.waterElectricity {
  padding: 20px 24px 0;

  .boxItem {
    font-size: 32px;
    color: #000000;
    background: #ffffff;
    box-shadow: 0px 0px 25px 0px rgba(57, 57, 57, 0.06);
    border-radius: 30px;
    padding: 0 24px 20px 24px;
    margin-bottom: 20px;

    .title {
      font-weight: bold;
      margin-bottom: 10px;
      border-bottom: 1px solid #ededed;
      height: 88px;
      line-height: 88px;
    }

    .box {
      .list {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 500;
        height: 68px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right {
          width: 13px;
          height: 22px;
          vertical-align: middle;
        }
      }
    }
  }

  .modelBox {
    width: 700px;
    background: #FFFFFF;
    padding: 0 24px;
    box-sizing: border-box;

    .title {
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      height: 98px;
      line-height: 98px;
      border-bottom: 1px solid #ededed;
      color: #000000;
    }

    .content {
      margin-top: 20px;
      padding: 20px;

      .list {
        height: 56px;
        line-height: 56px;
        font-size: 28px;
        color: #000000;
      }
    }

  }
}
</style>